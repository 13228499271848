            

<template >
    <div class="row" v-if="data.prix_australie"> 
        <div class="col-12 col-lg-12 wrapper-box-fiche"> 
            <div class="box-white-fiche">
                    <table class="comparateur2 text-center"  v-if="data.prix_australie.body_system || data.prix_australie.prescriber || data.prix_australie.schedule">
                        <thead>
                            <tr>
                                <th class="gray" width="10%" v-if="data.prix_australie.body_system">body system</th>
                                <th class="gray"  width="10%" v-if="data.prix_australie.prescriber">prescriber</th>
                                <th class="gray"  width="10%" v-if="data.prix_australie.schedule">schedule</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td v-if="data.prix_australie.body_system">
                                    {{data.prix_australie.body_system}}
                                </td>
                                <td v-if="data.prix_australie.prescriber">
                                    {{data.prix_australie.prescriber}}
                                </td>
                                <td v-if="data.prix_australie.schedule">
                                    {{data.prix_australie.schedule}}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table class="comparateur2 text-center" v-if="data.prix_australie.premium || data.prix_australie.additional">
                        <thead>
                            <tr>
                                <th class="gray" width="10%" v-if="data.prix_australie.premium">premium</th>
                                <th class="gray"  width="10%" v-if="data.prix_australie.additional">additional</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td v-if="data.prix_australie.premium">
                                    {{data.prix_australie.premium}}
                                </td>
                                <td v-if="data.prix_australie.additional">
                                    {{data.prix_australie.additional}}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table class="comparateur2 text-center" v-if="data.prix_australie.aemp || data.prix_australie.date || data.prix_australie.max_qty_packs || data.prix_australie.max_qty_units || ata.prix_australie.no_of_repeats || data.prix_australie.dpmq || data.prix_australie.dpma || data.prix_australie.max_amount || data.prix_australie.max_safety_net || data.prix_australie.max_price_to_consumer || data.prix_australie.price_ex_manufacturer">
                        <thead>
                            <tr>
                                <th class="gray"  width="10%" v-if="data.prix_australie.aemp">AEMP (approved ex-manufacturer price)</th>
                                <th class="gray"  width="10%" v-if="data.prix_australie.date">date</th>
                                <th class="gray"  width="10%" v-if="data.prix_australie.max_qty_packs">max qty packs</th>
                                <th class="gray"  width="10%" v-if="data.prix_australie.max_qty_units">max qty units</th>
                                <th class="gray"  width="10%" v-if="data.prix_australie.no_of_repeats">no of repeats</th>
                                <th class="gray"  width="10%" v-if="data.prix_australie.dpmq">DPMQ</th>
                                <th class="gray"  width="10%" v-if="data.prix_australie.dpma">DPMA</th>
                                <th class="gray"  width="10%" v-if="data.prix_australie.max_amount">max amount</th>
                                <th class="gray"  width="10%" v-if="data.prix_australie.max_safety_net">max safety net</th>
                                <th class="gray"  width="10%" v-if="data.prix_australie.max_price_to_consumer">max price to consumer</th>
                                <th class="gray"  width="10%" v-if="data.prix_australie.price_ex_manufacturer">price ex manufacturer</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td v-if="data.prix_australie.aemp">
                                    {{data.prix_australie.aemp}}
                                </td>
                                <td v-if="data.prix_australie.date">
                                    {{data.prix_australie.date}}
                                </td>
                                <td v-if="data.prix_australie.max_qty_packs">
                                    {{data.prix_australie.max_qty_packs}}
                                </td>
                                <td v-if="data.prix_australie.max_qty_units">
                                    {{data.prix_australie.max_qty_units}}
                                </td>
                                <td v-if="data.prix_australie.no_of_repeats">
                                    {{data.prix_australie.no_of_repeats}}
                                </td>
                                <td v-if="data.prix_australie.dpmq">
                                    {{data.prix_australie.dpmq}}
                                </td>
                                <td v-if="data.prix_australie.dpma">
                                    {{data.prix_australie.dpma}}
                                </td>
                                <td v-if="data.prix_australie.max_amount">
                                    {{data.prix_australie.max_amount}}
                                </td>
                                <td v-if="data.prix_australie.max_safety_net">
                                    {{data.prix_australie.max_safety_net}}
                                </td>
                                <td v-if="data.prix_australie.max_price_to_consumer">
                                    {{data.prix_australie.max_price_to_consumer}}
                                </td>
                                <td v-if="data.prix_australie.price_ex_manufacturer">
                                    {{data.prix_australie.price_ex_manufacturer}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
            </div>
        </div>
    </div>   
</template>


<script>
export default {
  name: "prix_australie",
  components: {},
  methods: {},
  mounted() {
    if (this.$i18n.locale == "de") {
      this.lang = "de";
    }
  },
  updated() {},
  computed: {
      data() {
            return this.$store.getters['detail/data']
        }
  },
  unmounted() {},
};
</script>